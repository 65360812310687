import React from 'react';
import PhotoPreview from '../../components/PhotoPreview/PhotoPreview';
import Code from '../Code/Code';

function Lance() {
	return (
		<div className="fade-up">
			<h3>Lance</h3>
			<p className="mb-3">I tried to create a professional network for freelancers, the main reason i began this project was to use most of my skills and to learn more</p>
			{/* <p className="mt-3 mb-3">
				Previously designed and co-engineered the first platform for solgt.no. Managed assets for $22m first year Previously designed.
			</p> */}

			<div className="photo-preview__img normal mb-5">
				<video width="100%" height="100%" className="m-auto" src="https://youtu.be/f16ec9WkB3U" controls />
			</div>

			<h4 className="mt-5 mb-3">Database</h4>
			<PhotoPreview img="lance/lds.png" desc="Entity relationship diagram" className="normal space" />

            <h4 className="mt-5 mb-3">Code </h4>
            <Code />
		</div>
	);
}

export default Lance;
