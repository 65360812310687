import React from 'react';
import './PhotoPreview.scss';

import { LazyLoadImage } from 'react-lazy-load-image-component';

function PhotoPreview({ img, desc, className = '' }) {
	return (
		<div className="photo-preview">
			{img.split('.')[1] === 'mp4' ? (
				<div className="photo-preview__img">
					<video width="100%" height="350px" className='m-auto' src={require(`../../assets/images/${img}`)} playsInline autoPlay loop muted />
				</div>
			) : (
				<LazyLoadImage
					src={require(`../../assets/images/${img}`)}
					className={`photo-preview__img ${className}`}
					width="100%"
					alt="Image Alt"
				/>
			)}
			<p className="sm">{desc}</p>
		</div>
	);
}

export default PhotoPreview;
