import React from 'react';
import './DataScience.scss';

import PhotoPreview from '../../components/PhotoPreview/PhotoPreview';
import imgData from '../../data/dataScience.json';

function DataScience() {
	return (
		<div className="grid col-2 fade-up">
			{imgData.map(image => (
				<a href={require('../../assets/PDF/' + image.src)} target="_blank" rel="noopener noreferrer" key={image.name}>
					<PhotoPreview img={image.name} desc={image.desc} className="lg" />
				</a>
			))}
		</div>
	);
}

export default DataScience;
