import React from 'react';
import './CodePreview.scss';

function CodePreview({ src, title, desc, tags, state }) {
	return (
		<div className={`code-preview mb-5 ${state}`}>
			<div className="flex-row r-sm align-items-center">
				<img src={src.includes('https') ? src : require(`../../assets/images/${src}`)} width="100px" />
				<div>
					<h4>{title}</h4>
					<p>{desc}</p>
					<div className="code-preview__tags mt-1">
						{tags.map(tag => (
							<div className="flex-row gap-sm align-items-center" key={tag.name}>
								<div className="mini-circle" style={{ backgroundColor: tag.colorCode }}></div>
								<p className="sm">
									{tag.name} <span style={{ opacity: '0.5', fontSize: '10px' }}>{tag.percentage}</span>
								</p>
							</div>
						))}
					</div>
				</div>
			</div>
			<img src={require(`../../assets/images/GitHub-Mark.png`)} width="30px" />
		</div>
	);
}

export default CodePreview;
