import React from 'react';
import CodePreview from '../../components/CodePreview/CodePreview';
import codeData from '../../data/code.json';

function Code() {
	return (
		<div className="fade-up">
			{codeData.map(codeProject =>
				codeProject.link ? (
					<a href={codeProject.link} target="_blank" rel="noopener noreferrer" key={codeProject.title}>
						<CodePreview
							src={codeProject.src}
							title={codeProject.title}
							desc={codeProject.desc}
							state={codeProject.state}
							tags={codeProject.tags}
						/>
					</a>
				) : (
					<CodePreview
						src={codeProject.src}
						title={codeProject.title}
						desc={codeProject.desc}
						state={codeProject.state}
						tags={codeProject.tags}
						key={codeProject.title}
					/>
				)
			)}
		</div>
	);
}

export default Code;
