import React from 'react';
import './Projects.scss';
import project from '../../data/projects.json';
import { Link } from 'react-router-dom';

function Projects() {
	return (
		<div className="grid col-3 fade-up">
			<a href="https://pokedex-iota-flame.vercel.app/" target="_blank" rel="noopener noreferrer">
				<div className="project">
					<img src={require(`../../assets/images/${project[0].thumbnail}`)} width="100%" />
					<div>
						<h4>{project[0].name}</h4>
						<p>{project[0].desc}</p>
						<p className="sm mt-1">{project[0].skills}</p>
					</div>
				</div>
			</a>
			<a href="https://www.mona.rent/" target="_blank" rel="noopener noreferrer">
				<div className="project">
					<img src={require(`../../assets/images/${project[1].thumbnail}`)} width="100%" />
					<div>
						<h4>{project[1].name}</h4>
						<p>{project[1].desc}</p>
						<p className="sm mt-1">{project[1].skills}</p>
					</div>
				</div>
			</a>

			<a href="https://youtu.be/f16ec9WkB3U" target="_blank" rel="noopener noreferrer">
				<div className="project">
					<img src={require(`../../assets/images/${project[2].thumbnail}`)} width="100%" />
					<div>
						<h4>{project[2].name}</h4>
						<p>{project[2].desc}</p>
						<p className="sm mt-1">{project[2].skills}</p>
					</div>
				</div>
			</a>
		</div>
	);
}

export default Projects;
