import React from 'react';

import imgData from '../../data/design.json';
import PhotoPreview from '../../components/PhotoPreview/PhotoPreview';

function Design() {
	return (
		<div className="grid col-2 fade-up">
			{imgData.map(image => (
				<PhotoPreview img={image.name} desc={image.desc} key={image.name} className="md"/>
			))}
		</div>
	);
}

export default Design;
