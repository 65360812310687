import React from 'react';
import './Photos.scss'

import imgData from '../../data/photos.json';
import PhotoPreview from '../../components/PhotoPreview/PhotoPreview';

function Photos() {
	return (
		<div className="grid col-3 fade-up">
			{imgData.map(image => (
				<PhotoPreview img={image.name} desc={image.desc} key={image.name}/>
			))}
		</div>
	);
}

export default Photos;
